import _ from "lodash";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { staticClass: "dlg-create-product-standard", attrs: { "ok-text": "Create", "title": "Add Product Standard", "after-close": _vm.afterModalClose, "width": 522 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v(" Add ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("auto-complete-input", { attrs: { "form-item": "", "label": "Item Code", "data-source": _vm.listItemMasters, "source": "itemCode", "rules": "required", "delete-filter": "IsInactive", "all-in-value": "", "custom-search": "", "has-icon-down": false, "value": _vm.itemCode }, on: { "search": _vm.onSearchItemCode, "change": _vm.onChangeItemCode } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Description", "rules": "required", "value": _vm.createForm.description, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Net Weight", "value": _vm.createForm.netWeight, "suffix": "KG", "disabled": true }, on: { "change": function($event) {
      return _vm.storeValue("netWeight", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Standard Speed", "suffix": "UPM", "rules": "required", "value": _vm.createForm.standardCPM, "type": "decimal" }, on: { "change": function($event) {
      return _vm.storeValue("standardCPM", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Bottleneck", "suffix": "UPM", "value": _vm.createForm.bottleneckCPM, "rules": "required", "type": "decimal" }, on: { "change": function($event) {
      return _vm.storeValue("bottleneckCPM", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Unit / Pack", "value": _vm.createForm.unitUOM, "disabled": true }, on: { "change": function($event) {
      return _vm.storeValue("unitUOM", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Production Line", "reference": "line-performance.common.local-production-lines", "custom-query": "areaId=" + _vm.area.value, "source": "id", "source-label": "code", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("productionLineID", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateProductStandard",
  inject: ["area", "resourceProps"],
  data() {
    const [productStandardProps, departmentsProps] = this.resourceProps;
    return {
      productStandardProps,
      departmentsProps,
      apiUrl: "#{VUE_APP_API_URL}#",
      createForm: {},
      defaultBottleneckCPM: 1,
      visible: false,
      isLoading: false,
      listItemMasters: [],
      countryCode: "",
      itemCode: ""
    };
  },
  created() {
    this.storeValue("areaID", this.area.value);
    let listDepartment = this.departmentsProps.crud.getList();
    this.countryCode = listDepartment.filter((department) => department.id === this.area.value)[0].country;
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    storeValue(resourceKey, value) {
      this.createForm = {
        ...this.createForm,
        [resourceKey]: value || ""
      };
      this.productStandardProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async onChangeItemCode(value) {
      if (!value) {
        this.itemCode = "";
        this.storeValue("code", "");
        this.storeValue("description", "");
        this.storeValue("netWeight", "");
        this.storeValue("unitUOM", "");
        this.storeValue("standardCPM", "");
        this.storeValue("bottleneckCPM", "");
        return;
      }
      if (this.area.value) {
        const res = await this.axios.get(`${this.apiUrl}/line-performance/common/standard-upm?itemCode=${value.itemCode}&areaID=${this.area.value}`);
        this.storeValue("standardCPM", res.data.standardUPM);
      }
      this.itemCode = value.itemCode;
      this.storeValue("bottleneckCPM", this.defaultBottleneckCPM);
      this.storeValue("code", value.itemCode);
      this.storeValue("description", value.itemDesc);
      this.storeValue("netWeight", value.netNetWgt);
      this.storeValue("unitUOM", value.unitsStockUom);
    },
    onSearchItemCode: _.debounce(async function(searchVal) {
      const { data } = await this.axios.get(`${this.apiUrl}/line-performance/common/bpcs-jde-item-masters?fe=((ItemCode StartsWith \`${searchVal}\`) and (CountryCode Eq \`${this.countryCode}\`))`);
      this.listItemMasters = data.results;
    }, 1e3),
    submit() {
      this.isLoading = true;
      this.productStandardProps.crud.submitEntity("create").then(() => {
        this.productStandardProps.crud.fetchList();
        this.visible = false;
        this.isLoading = false;
      }).catch(() => this.isLoading = false);
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push("/line-performance/product-standards");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateProductStandard = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-product-standard" }, [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "line-performance.common.item-masters", "resource-id-name": "itemCode" } }), _c("resource", { attrs: { "name": "line-performance.common.local-production-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": [
    "line-performance.product-masters",
    "identities.common.departments"
  ], "api-url": _vm.apiUrl, "redirect-route": ["/line-performance/product-standards"] } }, [_c("create-product-standard")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateProductStandard
  },
  data() {
    return {
      apiUrl,
      CreateProductStandard
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
